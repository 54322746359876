<template>
  <div id="login">
    <div>
      <div class="main">
        <div class="img-bg"></div>
        <div class="form">
          <h2 class="title">
            倍特睿
            <br />实验室信息管理系统
          </h2>
          <Form :model="formd" :rules="rules" @submit.native.prevent ref="loginForm">
            <FormItem prop="UserCode">
              <Input
                placeholder="请输入用户名"
                prefix="ios-contact-outline"
                size="large"
                v-model="formd.UserCode"
              />
            </FormItem>
            <FormItem prop="Password">
              <Input
                placeholder="请输入密码"
                prefix="ios-lock-outline"
                size="large"
                type="password"
                v-model="formd.Password"
              />
            </FormItem>
            <FormItem class="img-code" prop="ImgCode">
              <Input @on-enter="login" placeholder="请输入验证码" size="large" v-model="formd.ImgCode" />
              <img :src="CodeImg" @click="reCodeImg" alt="验证码" />
            </FormItem>
          </Form>
          <Button :loading="logining" @click="login" long size="large" type="primary">登录</Button>
          <a
            class="gw-link"
            href="http://www.beikekj.com/?fsrc=btr"
            style="text-decoration: none; color: rgb(147, 147, 147);"
            target="_blank"
          >
            <span>©2017-2021 贝壳科技</span>
          </a>
        </div>
      </div>
      <div style="text-align:center;padding-top:20px;" v-if="beianLink">
        <a class="beian-link" href="https://beian.miit.gov.cn">粤ICP备2021097951号</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formd: {},
      rules: {
        UserCode: [{ required: true, message: " " }],
        Password: [{ required: true, message: " " }],
        ImgCode: [{ required: true, message: " " }],
      },
      CodeImg: "/Home/ImgCode",
      logining: false,
      beianLink: true,
    };
  },
  mounted: function () {
    this.beianLink = /btrtest.cn/.test(window.location.href);
    document.getElementsByTagName("title")[0].innerHTML =
      "倍特睿实验室信息管理系统-登录";
    this.reCodeImg();
  },
  methods: {
    reCodeImg() {
      this.CodeImg = "/Home/ImgCode?t=" + new Date().getTime();
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.postLogin();
        }
      });
    },
    postLogin() {
      this.logining = true;
      this.$axios
        .post("/Api/BeikeUser/Login", this.formd)
        .then((res) => {
          this.logining = false;
          if (res.result) {
            this.$router.push({ path: "/" });
          } else {
            this.reCodeImg();
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.logining = false;
          this.reCodeImg();
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
#login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcfcfc;
  .main {
    width: 1000px;
    height: 500px;
    display: flex;
    box-shadow: 0 0 18px 3px #cfcfcf;
    background: #ffffff;
  }
  .img-bg {
    flex: 1;
    background: url("../assets/bg3.jpg");
    background-size: 700px 520px;
    background-position: center;
  }
  .form {
    width: 300px;
    padding: 30px;
    flex-shrink: 0;
    position: relative;
  }
  .title {
    text-align: center;
    padding: 30px 0;
  }
  .ivu-form {
    width: 100%;
  }
  .img-code {
    .ivu-form-item-content {
      display: flex;
    }
    img {
      width: 100px;
      height: 40px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .gw-link {
    position: absolute;
    bottom: 30px;
    left: 80px;
    width: 140px;
  }
  .beian-link {
    color: #cfcfcf;
    font-weight: bold;
    margin-top: 30px;
  }
}
</style>